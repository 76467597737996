<template>
  <layout
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div class="expande-horizontal wrap">
      <v-flex xs12 lg3>
        <Filtros class="pl-3" />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-insumos'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="pa-3" v-else xs12>
          <v-list
            class="pa-0 ma-0"
            dense
            style="min-height: 20vh; overflow: auto;"
          >
            <template v-for="(insumo, index) in get_insumos.docs">
              <v-list-item
                :class="{
                  'item-selected': get_insumo._id === insumo._id ? true : false
                }"
                @click="abririnsumo(insumo, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="insumo._id"
              >
                <v-list-item-content>
                  <v-list-item-title class="fonte-subtitulo font-weight-bold">
                    {{ insumo.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="{
                      'white--text':
                        get_insumo._id === insumo._id ? true : false
                    }"
                    class="fonte-mini fonte-subtitulo"
                  >
                    Tipo: {{ insumo.type }} 
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="{
                      'white--text':
                        get_insumo._id === insumo._id ? true : false
                    }"
                    class="fonte-mini fonte-subtitulo"
                  >
                    {{ $helper.formataSaldo(insumo.ultimo_preco_de_compra) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="animate__animated animate__fadeIn"
                    v-if="get_insumo._id === insumo._id"
                    icon
                  >
                    <v-icon color="white"> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <img style="width: 80%" src="img/empty.gif" alt="empty" />
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Nenhum item encontrado, clique no botão abaixo e cadastre um!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Novo item
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length" class="pa-3" xs12>
          <Paginacao />
        </v-flex>
      </v-flex>
      <!-- <v-flex xs0 md0 lg1></v-flex> -->
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <div
          v-if="get_insumo.new || get_insumo._id"
          class="expande-horizontal centraliza wrap"
        >
          <!-- Header de cadastro de insumos -->
          <v-flex class="mb-2" xs12>
            <div class="expande-horizontal fonte column">
              <transition name="slide-fade">
                <v-list-item class="pa-0 ma-0">
                  <v-avatar color="#47CF72" class="mr-2" icon>
                    <v-icon color="white">mdi-family-tree</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes do item
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="get_insumo._id">
                    <v-btn
                      color="red"
                      @click="excluir_insumo_dialog(get_insumo)"
                      outlined
                      small
                      >Excluir</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </transition>
            </div>
          </v-flex>
          <!-- Form de cadastro de insumos -->
          <v-flex xs12>
            <v-form ref="form">
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <div class="expande-horizontal">
                    <v-flex class="pt-3" xs12 md4>
                      <v-select
                        :items="['Placa', 'Acabamento', 'Peça Principal', 'Peça Secundária', 'Material']"
                        v-model="get_insumo.type"
                        dense
                        outlined
                        label="Tipo de item"
                      ></v-select>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md2>
                      <v-switch
                        label="Disponível"
                        class="fonte-subtitulo fonte-italica"
                        v-model="get_insumo.disponivel"
                      ></v-switch>
                    </v-flex>
                  </div>
                  <div class="expande-horizontal">
                    <ModalSendArchive />
                  </div>
                  <v-flex class="pr-3" xs12 md3>
                    <v-text-field
                      label="Nome do insumo"
                      dense
                      outlined
                      class="fonte-subtitulo fonte-italica"
                      placeholder="ex: Granito"
                      v-model="get_insumo.nome"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md2>
                    <v-text-field
                      label="Preço"
                      dense
                      outlined
                      type="number"
                      v-mask="['#.##', '##.##', '###.##', '####.##']"
                      class="fonte-subtitulo fonte-italica"
                      placeholder="ex: 12"
                      v-model="get_insumo.ultimo_preco_de_compra"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md2>
                    <v-text-field
                      label="Estoque mínimo"
                      dense
                      outlined
                      class="fonte-subtitulo fonte-italica"
                      placeholder="ex: 12"
                      v-model="get_insumo.estoque_minimo"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="" xs12 md3>
                    <v-select
                      label="Unidade do estoque mínimo"
                      dense
                      outlined
                      class="fonte-subtitulo fonte-italica"
                      placeholder="ex: KG"
                      type="Number"
                      :readonly="get_insumo._id ? true : false"
                      :items="unidades_de_medida_base"
                      item-text="nome"
                      return-object
                      @input="forceUpdate"
                      @change="forceUpdate"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      v-model="get_insumo.unidade_estoque_minimo"
                    ></v-select>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      class="fonte"
                      :color="$theme.third"
                      dark
                      outlined
                      @click="createOrUpdate"
                      >Salvar detalhes do item</v-btn
                    >
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <!-- Gerência do estoque -->
          <v-flex v-if="get_insumo._id" class="mt-6 mb-3" xs12>
            <div class="expande-horizontal fonte column">
              <v-flex class="mb-3" xs12>
                <transition name="slide-fade">
                  <v-list-item @click="iniciaCadastroEstoque" class="pa-0 ma-0">
                    <v-avatar color="#47CF72" class="mr-2" icon>
                      <v-icon dark>mdi-cube-send</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        {{ quantidadeEstoque.quantidade
                        }}{{
                          get_insumo && get_insumo.unidade_estoque_minimo
                            ? get_insumo.unidade_estoque_minimo.valor
                            : ""
                        }}
                        em estoque
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :color="$theme.third"
                        class="text-capitalize"
                        @click="iniciaCadastroEstoque"
                        small
                      >
                        <v-icon color="white" class="mr-1" size="15"
                          >mdi-plus-circle-outline</v-icon
                        >
                        <span class="white--text fonte fonte-mini"
                          >Add Estoque</span
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </transition>
              </v-flex>
              <v-flex xs12>
                <v-list three-line class="pa-0 ma-0">
                  <template v-for="estoque in get_estoques.docs">
                    <v-list-item
                      style="border-radius: 5px; background: #f2f2f2;"
                      class="ma-0 mb-2 pa-0"
                      :key="estoque._id"
                    >
                      <v-avatar
                        class="mx-3"
                        color="green"
                        v-if="estoque.quantidade > 0"
                      >
                        <v-icon size="18" color="white">
                          mdi-arrow-up-circle-outline
                        </v-icon>
                      </v-avatar>
                      <v-avatar class="mx-3" color="red" v-else>
                        <v-icon size="18" class="ml-2 mr-2" color="white">
                          mdi-arrow-down-circle-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content
                        class="fonte-subtitulo fonte-italica font-weight-bold"
                      >
                        <v-list-item-title>
                          {{ estoque.quantidade }}
                          {{
                            estoque && estoque.unidade_de_medida
                              ? estoque.unidade_de_medida.valor
                              : ""
                          }}
                          de
                          {{ estoque.produto.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Preço Base:
                          {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Total comprado:
                          {{ sumValueBuyed(estoque) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte-micro"
                          >{{
                            $moment(estoque.created_at).format(
                              "DD/MM/YY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_insumo._id || get_insumo.new ? true : false"
      >
        <v-card class="pl-3">
          <div
            v-if="get_insumo.new || get_insumo._id"
            class="expande-horizontal centraliza wrap"
          >
            <!-- Header de cadastro de insumos -->
            <v-flex class="mb-2" xs12>
              <div class="expande-horizontal fonte column">
                <transition name="slide-fade">
                  <v-list-item class="pa-0 ma-0">
                    <v-btn @click="backToList" class="mr-2" icon>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        Gerencie os detalhes do item
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="get_insumo._id">
                      <v-btn
                        color="red"
                        @click="excluir_insumo_dialog(get_insumo)"
                        outlined
                        small
                        >Excluir</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </transition>
              </div>
            </v-flex>
            <!-- Form de cadastro de insumos -->
            <v-flex xs12 class="pa-2">
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <div class="expande-horizontal">
                      <v-flex class="pt-3" xs12 md4>
                        <v-select
                          :items="['Placa', 'Acabamento', 'Peça', 'Material']"
                          v-model="get_insumo.type"
                          dense
                          outlined
                          label="Tipo de item"
                        ></v-select>
                      </v-flex>
                      <v-flex class="pr-3" xs12 md2>
                        <v-switch
                          label="Disponível"
                          class="fonte-subtitulo fonte-italica"
                          v-model="get_insumo.disponivel"
                        ></v-switch>
                      </v-flex>
                    </div>
                    <div class="expande-horizontal">
                      <ModalSendArchive />
                    </div>
                    <v-flex class="pr-3" xs12 md4>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: Granito Cinza"
                        v-model="get_insumo.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md2>
                      <v-text-field
                        label="Preço"
                        dense
                        outlined
                        type="number"
                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.ultimo_preco_de_compra"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4>
                      <v-text-field
                        label="Estoque mínimo"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.estoque_minimo"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="" xs12 md4>
                      <v-select
                        label="Unidade do estoque mínimo"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: M"
                        type="Number"
                        :readonly="get_insumo._id ? true : false"
                        :items="unidades_de_medida_base"
                        item-text="nome"
                        return-object
                        @input="forceUpdate"
                        @change="forceUpdate"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        v-model="get_insumo.unidade_estoque_minimo"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        class="fonte"
                        :color="$theme.third"
                        dark
                        outlined
                        @click="createOrUpdate"
                        >Salvar detalhes do item</v-btn
                      >
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Gerência do estoque -->
            <v-flex v-if="get_insumo._id" class="mt-6 pa-2 mb-3" xs12>
              <div class="expande-horizontal fonte column">
                <v-flex class="mb-3" xs12>
                  <transition name="slide-fade">
                    <v-list-item
                      @click="iniciaCadastroEstoque"
                      class="pa-0 ma-0"
                    >
                      <v-avatar color="#47CF72" class="mr-2" icon>
                        <v-icon dark>mdi-cube-send</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          {{ quantidadeEstoque.quantidade
                          }}{{
                            get_insumo && get_insumo.unidade_estoque_minimo
                              ? get_insumo.unidade_estoque_minimo.valor
                              : ""
                          }}
                          em estoque
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :color="$theme.third"
                          class="text-capitalize"
                          @click="iniciaCadastroEstoque"
                          small
                        >
                          <v-icon color="white" class="mr-1" size="15"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="white--text fonte fonte-mini"
                            >Add Estoque</span
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition>
                </v-flex>
                <v-flex xs12>
                  <v-list three-line class="pa-0 ma-0">
                    <template v-for="estoque in get_estoques.docs">
                      <v-list-item
                        style="border-radius: 5px; background: #f2f2f2;"
                        class="ma-0 mb-2 pa-0"
                        :key="estoque._id"
                      >
                        <v-avatar
                          class="mx-3"
                          color="green"
                          v-if="estoque.quantidade > 0"
                        >
                          <v-icon size="18" color="white">
                            mdi-arrow-up-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-avatar class="mx-3" color="red" v-else>
                          <v-icon size="18" class="ml-2 mr-2" color="white">
                            mdi-arrow-down-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-list-item-content
                          class="fonte-subtitulo fonte-italica font-weight-bold"
                        >
                          <v-list-item-title>
                            {{ estoque.quantidade }}
                            {{
                              estoque && estoque.unidade_de_medida
                                ? estoque.unidade_de_medida.valor
                                : ""
                            }}
                            de
                            {{ estoque.produto.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Preço Base:
                            {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Total comprado:
                            {{ sumValueBuyed(estoque) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="fonte-micro"
                            >{{
                              $moment(estoque.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      fullscreen
      persistent
      v-model="formEstoqueDialog"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    >
      <div
        class="expande-horizontal px-3 centraliza"
        style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
      >
        <v-card width="500" class="expande-horizontal wrap">
          <div class="expande-horizontal px-1 pr-3 py-3 pb-0">
            <v-btn dark @click="formEstoqueDialog = false" icon>
              <v-icon color="orange">
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <span
              style="font-size: 17pt; width: 100%;"
              class="fonte-subtitulo font-weight-bold fonte-italica"
            >
              + {{ get_insumo.nome }}
            </span>
            <v-btn icon @click="formEstoqueDialog = false">
              <v-icon color="orange">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="expande-horizontal pa-3 wrap">
            <v-flex xs12>
              <div class="fonte expande-horizontal centraliza">
                <h1 class="fonte-big">
                  {{ get_estoque.quantidade }}
                </h1>
                <span class="pt-2" v-if="get_estoque.unidade_de_medida">{{
                  get_estoque.unidade_de_medida.valor
                }}</span>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-form ref="formEstoque">
                <div class="expande-horizontal wrap">
                  <v-flex class="pa-1" xs12>
                    <span class="fonte-italica fonte-subtitulo">
                      Quantidade
                    </span>
                    <v-text-field
                      solo
                      flat
                      autofocus
                      ref="qtdEstoque"
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 100"
                      type="Number"
                      hide-details
                      :rules="[
                        v => !!v || 'Campo obrigatório',
                        v => v > 0 || 'Valor deve ser maior que zero'
                      ]"
                      v-model="get_estoque.quantidade"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="
                      get_estoque.unidade_de_medida &&
                        get_estoque.unidade_de_medida.nome === 'CAIXA'
                    "
                    class="pa-1 pt-0"
                    xs12
                  >
                    <span class="fonte-italica fonte-subtitulo">
                      Quantidade de itens por caixa
                    </span>
                    <v-text-field
                      solo
                      flat
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 10"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      type="Number"
                      v-model="get_estoque.unidade_de_medida.divisor"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pa-1 pt-0" xs12>
                    <span class="fonte-italica fonte-subtitulo">
                      Preço
                      <span
                        class="text-lowercase"
                        v-if="get_estoque.unidade_de_medida"
                        >por {{ get_estoque.unidade_de_medida.nome }}</span
                      >
                    </span>
                    <v-text-field
                      solo
                      flat
                      prefix="R$"
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: 10.50"
                      @keyup.enter.prevent="adicionarEstoque"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      v-mask="['#,##', '##,##', '###,##', '####,##']"
                      v-model="get_estoque.preco_de_compra"
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-form>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal">
                <v-btn
                  @click="adicionarEstoque"
                  color="green"
                  dark
                  class="fonte-subtitulo fonte-italica"
                  >Adicionar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveById from "../../shared/components/ModalSendById.vue";
export default {
  data() {
    return {
      card: false,
      contextDialog: false,
      drawer: false,
      formEstoqueDialog: false,
      options: [
        {
          nome: "Novo item",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
        // {
        //   nome: "Estoque",
        //   action: this.iniciaCadastroEstoque,
        //   icon: "mdi-plus"
        // }
      ],
      rotas: [
        {
          name: "Início",
          path: ""
        },
        {
          name: "Estoques e Adicionais",
          path: "/insumos"
        }
      ],
      get_categoria: {
        insumos: []
      },
      nova_secao: "",
      formPrecoMultiplo: {},
      unidades_de_medida: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "GRAMA(g)",
          valor: "g",
          divisor: 1
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "Mililitro (ML)",
          valor: "ML",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "CAIXA",
          valor: "CX",
          divisor: 1
        }
      ],
      unidades_de_medida_base: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "Metro Quadrado",
          valor: "M2",
          divisor: 100
        }
        // {
        //   nome: "CAIXA",
        //   valor: "CX"
        // },
        // {
        //   nome: "PACOTE",
        //   valor: "PCT",
        //   divisor: 1
        // }
      ]
    };
  },
  filters: {
    precoDeEntrada(val) {
      const quantidade = parseFloat(val.quantidade);
      const preco_de_compra = parseFloat(val.preco_de_compra);
      const preco_por_divisor = preco_de_compra / val.divisor;
      const total_de_entrada = quantidade * preco_por_divisor;
      return total_de_entrada;
    }
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    ModalSendArchive,
    ModalSendArchiveById
  },
  computed: {
    ...mapGetters([
      "get_tenants",
      "get_estoque",
      "get_estoques",
      "get_tenants_filtros",
      "get_insumos_filtros",
      "get_estoques_filtros",
      "get_insumocategorias",
      "get_insumocategoria",
      "getLoggedUser",
      "get_insumo",
      "get_insumos",
      "getLinksById",
      "getPersonalizedLoading"
    ]),
    quantidadeEstoque() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(
          val.preco_de_compra ? val.preco_de_compra : 0
        );
        const preco_por_divisor =
          preco_de_compra / (val.divisor ? val.divisor : 0);
        const total_de_entrada = quantidade * (preco_por_divisor || 0);
        return total_de_entrada;
      };
      let totalEstoque = {
        quantidade: 0,
        valor_total: 0
      };
      this.get_estoques.docs.map(estoque => {
        (totalEstoque.quantidade += estoque.quantidade),
          (totalEstoque.valor_total += calcPreco(estoque));
      });
      totalEstoque.quantidade = totalEstoque.quantidade.toFixed(2);

      return totalEstoque;
    }
  },
  methods: {
    excluir_insumo_dialog(item) {
      let text = "Deseja remover este insumo?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_insumo",
        action_value: item
      });
    },
    excluir_estoque_dialog(item) {
      let text = "Deseja remover registro do estoque?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_estoque",
        action_value: item
      });
    },
    backToList() {
      this.$store.commit("set_insumo", {});
      this.$forceUpdate();
    },
    sumValueBuyed(val) {
      let qtd = val.quantidade || 0;
      let priceBuy = val.preco_de_compra || 0;
      let sum = qtd * priceBuy;
      return this.$helper.formataSaldo(sum);
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    iniciaCadastroEstoque() {
      this.formEstoqueDialog = true;
      this.get_estoque.unidade_de_medida = this.get_insumo.unidade_estoque_minimo;
      // this.$refs.qtdEstoque.focus();
    },
    abririnsumo(insumo) {
      this.$store.commit("set_insumo", insumo);
      this.$store.commit("setLinks", insumo.imagens);
      this.get_estoques_filtros.produto = insumo;
      this.listar_estoques();
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_insumo.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_insumo.preco_multiplo = novoPrecoMultiplo;
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    closeContextDialog() {
      this.contextDialog = false;
      setTimeout(() => {
        this.drawer = true;
      }, 10);
    },
    poc(text) {
      console.log(text);
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_insumos",
      "criar_estoque",
      "listar_insumocategorias",
      "criar_insumo",
      "listar_estoques",
      "atualizar_insumo",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        this.get_insumo.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
      }
    },
    criarOuAtualizar() {
      if (this.get_insumo._id) {
        this.atualizar_insumo();
      } else {
        this.criar_insumo();
      }
    },
    adicionarEstoque() {
      if (this.$refs.formEstoque.validate()) {
        this.get_estoque.produto = this.get_insumo;
        this.get_estoque.origem = "insumo";
        this.criar_estoque();
        this.formEstoqueDialog = false;
      }
    },
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_insumo", {
        new: true,
        preco_multiplo: []
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
      this.$store.commit("set_estoques", {
        docs: [],
        page: 1,
        totalPages: 1
      });
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_insumo._id) {
          this.atualizar_insumo();
        } else {
          this.criar_insumo();
        }
      }
    }
  },
  created() {
    this.get_insumos_filtros.limitPerPage = 7;
    this.listar_insumos();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #010f50;
}
.item-selected {
  background: #010f50 !important;
  color: #fff !important;
}
</style>
