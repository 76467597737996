<template>
  <v-pagination
    style="background: #fff;"
    :color="$theme.secondary"
    total-visible="7"
    circle
    class="fonte-subtitulo fonte-italica"
    :elevation="0"
    v-model="get_insumos_filtros.page"
    :length="get_insumos.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_insumos", "get_insumos_filtros"])
  },
  methods: {
    ...mapActions(["listar_insumos"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_insumos_filtros.page = page;
        this.listar_insumos();
      }
    }
  }
};
</script>
